/* Core Variables and Mixins */

@import "../../ga/styles/partials/variables";
@import "../../ga/styles/partials/mixins";

/* Basic foundation reset and print styles */

@import "../../ga/styles/partials/foundation";
@import "../../ga/styles/partials/print";

/* Core CSS
 *
 * NOTE - _type.css: Contains _typescale.css: written and compiled from scss folder
 * DO NOT EDIT _grid.css - Written and compiled from scss folder
 */

@import "../../ga/styles/partials/accessibility";
@import "../../ga/styles/partials/buttons";
@import "../../ga/styles/partials/forms";
@import "../../ga/styles/partials/grid";
@import "../../ga/styles/partials/images";
@import "../../ga/styles/partials/lists";
@import "../../ga/styles/partials/tables";
@import "../../ga/styles/partials/type";

/* Components */

@import "../../ga/styles/partials/animations";
@import "../../ga/styles/partials/close";
@import "../../ga/styles/partials/dropdowns";
@import "../../ga/styles/partials/footers";
@import "../../ga/styles/partials/icons";
@import "../../ga/styles/partials/jumbotrons";
@import "../../ga/styles/partials/modals";
@import "../../ga/styles/partials/nav";
@import "../../ga/styles/partials/navbar";
@import "../../ga/styles/partials/pagination";
@import "../../ga/styles/partials/search";
@import "../../ga/styles/partials/sections";
@import "../../ga/styles/partials/sidenav";
@import "../../ga/styles/partials/simon-search";
@import "../../ga/styles/partials/spo";
@import "../../ga/styles/partials/utilities";
@import "../../ga/styles/partials/headerfooter";


/* Components with JS */

@import "../../ga/styles/vendor/smartbanner/smartbanner";

/* App Specific */

/* Site Content - below header, above footer */

main {
    margin-top: calc(5.5em + (var(--navbar-height)));

    @media (--md-viewport-down) {
        margin-top: calc(var(--navbar-global-height) + var(--navbar-mobile-height));
    }
}

.simon-section {
    padding-bottom: .5em;
}

.vip-hero {
  background-color: #d2d2d2;
}

.vip-hero2 {
  background-color: #f1f1f1;
}

.vip-hero-holiday {
  background-color: #dd2229;
}

.simon-section-header {
  margin-bottom: 3.30947rem;

  @media (--md-viewport-down) {
    margin-bottom: 1.7rem;
  }

  & > span.header-sm,
  & > span.header-xs {
    display: block;
  }
}

.HtmlContentClass {
  margin-bottom: 1.9rem;
}

.simon-section-aside > ul.aside-group {
  margin-top: 1.5rem;
}

.subnav {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.76) 0%, rgba(0, 0, 0, 0.72) 63.83%, rgba(0, 0, 0, 0.56) 75.69%, rgba(0, 0, 0, 0.40) 87.55%, rgba(0, 0, 0, 0.27) 95.1%, rgba(0, 0, 0, 0.14) 99.61%, rgba(0, 0, 0, 0.02) 105.22%);
}

.jumbotron .container {
  position: relative;
}

.jumbotron .vip-logo {
  position: absolute;
  bottom: -0.9256rem;
  left: 0;
  width: 675px;

  @media (--lg-viewport-down) {
    width: 400px;
  }
}

@media (min-width: 1600px) {
    .container {
        max-width: 1500px !important;
    }
}

.navbar-nav .vip-nav-welcome,
.vip-nav-welcome {
  padding-bottom: calc((50px - 1.3rem + 2px) / 2);
  padding-top: calc((50px - 1.3rem - 2px) / 2);
  font-size: 1.3rem;
  letter-spacing: -0.03em;

  &.nav-link {
    padding-bottom: calc((74px - 1.3rem + 2px) / 2);
    padding-top: calc((74px - 1.3rem - 2px) / 2);
  }
}

section.vip-register div.HtmlContentClass {
  margin-bottom: 0px;
}

.vip-register-section {
    padding-top: 0.5em;
}

.vip-register-button {
    padding-bottom: 0.95em;
}

input.btn:not([type=radio]):not([type=checkbox]) {
  border-radius: 4px;
}

/* Other Pages */

@import "partials/login";
@import "partials/profile";
@import "partials/faq";
@import "partials/offers";
@import "partials/mall";
@import "partials/print";

/* Utilities */

@import "../../ga/styles/partials/utilities";
