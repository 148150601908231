/* MALL LIST */

.mall-list-item {
    margin-bottom: 30px;
    position: relative;
    background-color: var(--black);
    &:hover .mall-list-item-img

{
    opacity: 0.7;
    backface-visibility: hidden;
    transform: translateZ(0) scale(1, 1);
}

&-text {
    position: absolute;
    width: 100%;
    top: 50%;
    transform: translateY(-50%) scale(1, 1);
    left: 0;
    color: var(--white);
    text-align: center;
    padding: 0 var(--spacer);
    backface-visibility: hidden;
}

&-img {
    opacity: 0.5;
    backface-visibility: hidden;
    transform: translateZ(0) scale(1, 1);

    @mixin transition opacity;
}

&-links {
    position: absolute;
    z-index: 2;
    right: 15px;
    background-color: rgba(0, 0, 0, 0.8);
    left: 15px;
    bottom: 30px;
    font-size: 11px;
    text-align: center;
    color: #fff;
    opacity: 1;
    padding: 10px 5px;
    transition: opacity 0.5s ease, bottom 0.3s ease;
    
    &.preferred-mall {
      background-color: var(--brand-primary);
      & a:focus {
        color: var(--gray-lighter) !important;
      }
    }

@media (--md-viewport-down) {
    bottom: 0;
}

}

  & .center-has-favorites {
    position: absolute;
    top: 0.4rem;
    right: 0.5rem;
    text-align: center;
    color: #fff;
    font-size: 0.75rem;
    z-index: 2;

    & svg {
      display: block;
      margin: 0 auto;
    }
    
    @media (--sm-viewport-down) {
      & > span {
        float: left;
        display: block;
        margin-top: 0.45rem;
        margin-right: 0.3rem;
      }
      & svg {
        float: right;
      }
    }
  }
}

.mall-hero {
    .jumbotron-title

{
    margin-bottom: 1.10833rem;
}

.jumbotron-desc {
    margin-bottom: 0;
    font-family: "SimonCircularPro-Book", Arial, Helvetica, sans-serif;
    font-size: var(--base-font-size);

    @media (--lg-viewport-up) {
        font-size: var(--font-size-h5);
    }
}
}
