.product-search-form {
    display: flex;
    position: relative;
    padding-top: 1rem;
    padding-bottom: 1.73612rem;
    margin-top: -1rem;

    @media (--md-viewport-down) {
        padding-bottom: 1rem;
    }

    & > :not(form) {
        margin-left: 1.73612rem;
    }

    & > form {
        flex-grow: 1;
    }

    & #searchForm {
        position: relative;
    }

    & > form input {
        border: none;
        background-color: var(--white);
        padding-left: 4rem;

        &.clear-button-active {
            padding-right: 4rem;
        }

        & ~ .search-button {
            position: absolute;
            left: 0;
            top: 0;
            padding-left: 1.25rem;
            padding-right: 1.25rem;
            padding-top: 0.55rem;
            margin: 0;

            & svg {
                width: 1.125rem;
                height: 2rem;
                fill: var(--gray);
            }

            &.search-button-clear {
                left: auto;
                right: 0
            }
        }

        &:focus ~ .search-suggestions {
            display: block;
        }
    }

    & .btn {
        height: 53px;
        padding-top: 0;
        padding-bottom: 0;
        line-height: 53px;

        & > svg {
            margin-left: 0.75rem;
        }
    }

    .filter-dropdown {
        position: relative;

        & .filter-dropdown-menu {
            position: absolute;
            top: 53px;
            right: 0;
            background-color: var(--white);
            border: 1px solid var(--brand-gray);
            max-width: calc(100vw - 3rem);
            z-index: 3;

            & > *:not(:last-child) {
                border-bottom: 1px solid var(--brand-gray);
            }

            & button {
                width: 100%;
                display: block;
                padding: 0.5rem 1rem;
                white-space: nowrap;

                &:hover, &:focus, &[disabled] {
                    background: var(--gray-light);
                }
            }
        }
    }

    & .search-suggestions {
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        background-color: var(--white);
        border: 1px solid var(--gray-light);
        border-top: none;
        z-index: 2;

        & button {
            padding: 0.5rem 1rem;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            display: block;
            border-top: 1px solid var(--gray-light);
            width: 100%;
            text-align: left;

            &:hover,
            &:focus,
            &.active {
                background-color: var(--gray-light);
            }
        }
    }

    @media (--md-viewport-down) {
        display: block;

        & .product-search-buttons {
            display: flex;
            margin-left: 0;
            margin-top: 1rem;

            & > *,
            & > button.btn {
                width: 50%;
            }

            & button {
                width: 100%;
            }
        }
    }
}

#search-sticky {
    position: sticky;
    top: 74px;
    z-index: 10;
    background-color: var(--gray-lighter);
}

.applied-filters-slider {
    & .applied-filter {
        margin-right: 0;
    }

    & .slick-slide {
        padding-right: 0.5rem;
    }

    & .slick-arrow {
        height: 100%;
        opacity: 1;
        background-image: none;

        &:hover::before,
        &:focus::before {
            opacity: 1;
        }

        &.slick-disabled {
            display: none;
            opacity: 0;
        }

        &.slick-next:not(.slick-disabled)::before {
            content: '';
            background: var(--icon-right-arrow);
            background-repeat: no-repeat;
            background-position: 100%;
            z-index: 2;
            position: absolute;
            right: 0;
            width: 0.75rem;
            top: 0;
            bottom: 0;
            opacity: 0.5;
        }

        &.slick-prev:not(.slick-disabled)::before {
            content: '';
            background: var(--icon-left-arrow);
            background-repeat: no-repeat;
            background-position: 100%;
            z-index: 2;
            position: absolute;
            left: 0;
            width: 0.75rem;
            top: 0;
            bottom: 0;
            opacity: 0.5;
        }

        &.slick-next:not(.slick-disabled)::after {
            content: '';
            height: 100%;
            display: block;
            position: absolute;
            top: 0;
            right: 0;
            width: 200%;
            background: linear-gradient(90deg, rgba(241,241,241,0) 0%, rgba(241,241,241,1) 50%, rgba(241,241,241,1) 100%);
            pointer-events: none;
        }

        &.slick-prev:not(.slick-disabled)::after {
            content: '';
            height: 100%;
            display: block;
            width: 200%;
            top: 0;
            left: 0;
            position: absolute;
            background: linear-gradient(90deg, rgba(241,241,241,1) 0%, rgba(241,241,241,1) 50%, rgba(241,241,241,0) 100%);
            pointer-events: none;
        }
    }
}
#applied-filters-wrapper {
    position: relative;
    transition: height 0.3s;
    overflow: hidden;
    position: absolute;
    width: 100%;
    top: calc(100% - 1px);
}

.applied-filters-hidden {
    opacity: 0;
    pointer-events: none;
    user-select: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
}

.simon-search-list-view-header {
    position: relative;
    z-index: 11;

    @media (--md-viewport-up) {
        display: flex;
        align-items: flex-end;
        margin-bottom: 1.5rem;
    }

    & h1 {
        font-size: 1.85rem;

        @media (--xl-viewport-down) {
            font-size: 1.8rem;
        }
    }
}

#productFiltersWrapper {
    @media (--md-viewport-up) {
        top: calc(74px + 1rem);
        position: sticky;

        &.sidebar-with-button-below #productFilters {
            max-height: calc(100vh - 74px - 54px - 3rem);
        }
    }

    & .store-directory-print {
        margin-top: 1rem;
        padding-left: 0.7rem;
        padding-right: 0.7rem;
        white-space: normal;
    }

    /* Firefox */
    & * {
        scrollbar-width: auto;
        scrollbar-color: var(--gray-light) var(--white);
    }

    /* Chrome, Edge, and Safari */
    & *::-webkit-scrollbar {
        width: 10px;
        z-index: 999;
    }

    & *::-webkit-scrollbar-track {
        background: var(--white);
    }

    & *::-webkit-scrollbar-thumb {
        background-color: var(--gray-light);
        border-radius: 0px;
        border: 2px solid var(--white);
    }
}

#productFilters {
    background-color: var(--white);
    border: 1px solid var(--brand-gray);
    overflow-y: auto;
    max-height: calc(100vh - 74px - 2rem);

    @media (--md-viewport-down) {
        position: fixed;
        top: 0;
        bottom: 0;
        left: -100%;
        width: 100%;
        z-index: 9999;
        opacity: 0;
        transition: opacity 0.3s, left 0.3s;
        max-height: unset;


        & .product-filter-body {
            height: calc(100vh - 74px - 53px);
            overflow-y: scroll;
        }

        &.open {
            display: block;
            opacity: 1;
            left: 0;
        }
    }

    & ul {
        padding: 0;
        margin: 0;
    }

    & .product-filter-body {
        padding: 0;
        margin: 0;

        & > li > button {
            display: block;
            padding: 0 2.81rem 0 1rem;
            width: 100%;
            text-align: left;
            position: sticky;
            top: -1px;
            background: var(--white);
            z-index: 4;
            height: 3.068rem;

            & svg {
                fill: var(--black);
                width: 0.81rem;
                height: 0.81rem;
                position: absolute;
                right: 1rem;
                top: 50%;
                transform: translateY(-50%);
            }
        }

        & > *:not(:last-child) {
            border-bottom: 1px solid var(--brand-gray);
        }
    }

    & li {
        padding: 0;
        list-style: none;
    }

    .checkbox label {
        display: flex;
        align-items: center;
    }

    .accordion-body {
        transition: height 0.3s;
        margin: 0 1rem;
        overflow: hidden;

        & > ul {
            padding-bottom: 1rem;
            margin-top: 2px;
        }
    }

    #Coming_Soon_accordion > ul {
        & > li {
            margin-bottom: 0.5rem;
        }

        & button {
            padding: 0px;
            text-align: left;
            position: relative;
            display: inline-block;

            & .icon-group {
                display: flex;
                align-items: center;
            }

            & svg {
                flex-shrink: 0;
                margin-right: 0.5rem;
                width: 1.25rem;
                height: 1.25rem;
                position: relative;
                top: -1px;
            }
        }
    }

    #Size_accordion {
        margin: 0;
    }

    #Size_accordion > ul {
        margin-left: 0.75rem;
        margin-right: 0.75rem;

        & li {
            display: inline-block;
            margin: 0 0.25rem 0.5rem 0.25rem;

            &.facet-filter-search {
                margin-bottom: 1rem;
                width: calc(100% - 0.5rem);
            }
        }

        & label {
            padding: 0.5rem;
            border: 2px solid black;
            border-radius: 3px;
            font-family: "SimonCircularPro-Bold", Arial, Helvetica, sans-serif;
            text-transform: uppercase;

            &:hover {
                background-color: var(--gray-light);
            }

            &.active {
                border-color: var(--brand-primary);
                color: var(--brand-primary);
            }
        }

        & .c-indicator {
            display: none;
        }
    }

    #Color_accordion > ul {
        display: flex;
        flex-wrap: wrap;

        & li {
            flex-shrink: 0;
            margin-top: 0;

            &.facet-filter-search {
                width: 100%;
                padding: 0;
                flex-shrink: 1;
            }
        }

        & label {
            flex-direction: column;
            padding: 0;
            text-align: center;

            &.active .c-indicator,
            & input:focus ~ .c-indicator {
                border-width: 2px;
                border-color: var(--white);
                outline: 2px solid var(--black);
            }
        }

        & .c-indicator {
            border-radius: 50%;
            width: 2.45rem;
            height: 2.45rem;
            position: relative;
            flex-shrink: 0;
            margin-bottom: 0.25rem;
            background: var(--gray-light);
            outline: 2px solid transparent;
            transition: outline 0.2s;

            &.color-multi {
                background: linear-gradient(0deg, rgba(51,51,221,1) 0%, rgba(221,51,51,1) 25%, rgba(221,221,51,1) 50%, rgba(51,153,51,1) 75%, rgba(51,51,221,1) 100%);
            }
        }
    }

    #Category_accordion > ul,
    #Product_Categories_accordion > ul {
        & .facet-filter-search {
            margin-left: 0;
        }

        & .accordion-body > ul {
            padding-bottom: 0.25rem;
        }

        & > li {
            margin-right: -1rem;

            & > button {
                position: relative;
                display: block;
                width: 100%;
                text-align: left;
                padding: 0.5rem 3rem 0.5rem 0;
            }

            & svg {
                width: 0.81rem;
                height: 0.81rem;
                position: absolute;
                right: 1rem;
                top: 50%;
                transform: translateY(-50%);
            }
        }

        & .accordion-body {
            margin-left: 0;
        }
    }


    .product-filter-header {
        height: 72px;
        line-height: 72px;
        position: relative;
        border-bottom: 1px solid var(--brand-gray);

        & .product-filter-close {
            width: 72px;
            height: 72px;
            position: absolute;
            top: 0;
            right: 0;

            & svg {
                width: 1rem;
                height: 1rem;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }
    }

    .product-filter-footer {
        position: fixed;
        bottom: 0px;
        display: flex;
        width: 100%;

        & button {
            height: 53px;
            line-height: 53px;
            transition: all 0.3s;
            width: 100%;

            &.filter-footer-clear {
                background-color: var(--white);
                color: var(--black);

                &:hover,
                &:focus {
                    background-color: var(--brand-gray);
                    color: var(--white);
                }
            }

            &.filter-footer-close {
                background-color: var(--black);
                color: var(--white);

                &:hover,
                &:focus {
                    background-color: var(--brand-gray);
                    color: var(--white);
                }
            }
        }
    }

    .facet-filter-search {
        height: 3.14rem;
        padding-left: 1rem;
        padding-right: 1rem;
        position: sticky;
        top: calc(3.068rem - 1px);
        background: var(--white);
        z-index: 3;
        overflow: hidden;

        & .facet-filter-input {
            padding: 0.5rem;
            height: 2.125rem;
            min-height: 0;
            padding-right: 1.5rem;
        }

        & .remove-filter {
            position: absolute;
            top: 1.0625rem;
            right: 1rem;
            transform: translateY(-50%);
            padding: 0.5rem;

            &:hover svg,
            &:focus svg {
                fill: var(--brand-primary)
            }

            & svg {
                width: 0.6rem;
                height: 0.6rem;
            }
        }
    }
}

.product-list {
    @media (max-width: 768px) {
        margin: 0 -0.5rem;

        & > .directory-store {
            padding: 0 0.5rem;

            & > .card-secondary {
                margin-bottom: 1rem;
            }
        }
    }

    & > .directory-store {
        @media (min-width: 400px) and (max-width: 546px) {
            width: 50%;
        }

        & > .card-secondary {
            @media (max-width: 543px) {
                height: 100%;
            }
        }
    }

    & .card-secondary {
        text-decoration: none;

        & .product-info {
            padding: 1.5rem 1rem;
            border-top: 1px solid var(--brand-gray);

            @media (--sm-viewport-down) {
                padding: 1rem 0.5rem;
            }

            & > *:not(:first-child) {
                margin-top: 0.5rem;
            }
        }

        & .product-brand,
        & .product-price {
            font-size: 0.81rem;
        }

        @media (--sm-viewport-down) {
            padding: 0;
            border: none;
            display: block;
        }
    }

    & .product-list-slick-slider .slick-slide div {
        display: block !important;
    }
}

.product-details {
    & > .simon-section:first-child {
        padding-top: 1.7rem;

        @media (--sm-viewport-down) {
            padding-top: 0;
        }
    }

    & .product-option-header {
        font-size: 0.81rem;
        margin-top: 1rem;
    }

    .product-description, 
    .product-description * {
        font-size: 0.81rem;

        & ul {
            padding-left: 1rem;
        }
    }

    & .product-brand-logo {
        max-width: 12rem;

        @media (--md-viewport-down) {
            max-width: 100%;
            border: 1px solid var(--gray-light);
        }
    }

    & .product-details-logo-row {
        @media (--md-viewport-down) {
            margin-top: 1.7rem;
            margin-bottom: 1.7rem;
        }
    }
    
    & .slick-slide {
        padding: 0 2px;
    }

    & .slick-list {
        margin: 0 -2px;
    }

    & .slick-dots {
        position: absolute;
        bottom: 1rem;
        left: 50%;
        transform: translateX(-50%);
    }

    & .slick-dots li.slick-active button:before {
        opacity: 1;
        background-color: var(--brand-primary);
        border: 1px solid var(--white);
    }

    & .slick-dots li button:before {
        opacity: 1;
        background: var(--gray-light);
        border-radius: 50%;
        content: '';
        border: 1px solid var(--white);
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 0.75rem;
        height: 0.75rem;
        transition: background-color 0.3s;
        box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.33);
    }

    & .slick-dots li button:hover:before,
    & .slick-dots li button:focus:before {
        background-color: var(--gray);
    }

    .product-slider-thumbnails .slick-track {
        margin: auto;
    }
}

.product-options {
    margin: 0.5rem 0;
}

.product-option {
    border: 1px solid var(--brand-gray);
    line-height: 1;
    margin: 0.5rem 1rem 0.5rem 0;
    padding: 0;
    transition: outline 0.2s, border 0.2s;
    outline: 0px solid transparent;

    & span {
        padding: 0.5rem 0.75rem;
        display: block;
    }

    & img {
        display: block;
        margin: 1px;
        max-width: 4rem;
    }

    &:hover,
    &:focus {
        background-color: var(--gray-light);
    }

    &[disabled],
    &.disabled {
        opacity: 0.5;
        color: var(--brand-gray);
    }

    &.active {
        border: 1px solid var(--brand-primary);
        outline: 1px solid var(--brand-primary);
    }
}

#applied-filters-header {
    @media (--md-viewport-up) {
        margin-top: -1.075rem;

        & > p {
            margin-bottom: 0.25rem
        }
    }
}

.applied-filters {
    padding-bottom: 0.25rem;
    background-color: var(--gray-lighter);
    transition: opacity 0.3s;

    @media (--md-viewport-down) {
        padding-bottom: 1rem;
    }
}

.applied-filter {
    display: inline-block;
    position: relative;
    padding: 0.25rem;
    padding-right: 2rem;
    background: var(--white);
    margin: 0.25rem 0.5rem 0.25rem 0;

    & p {
        margin-bottom: 0.25rem;
        font-size: var(--input-font-size);
    }

    & .remove-filter {
        position: absolute;
        top: 0;
        right: 0;
        padding-right: 0.25rem;
        padding-top: 0.25rem;
        padding-left: 1.5rem;
        padding-bottom: 1.5rem;

        &:hover,
        &:focus {
            & svg {
                fill: var(--brand-primary);
            }
        }

        & svg {
            width: 0.5rem;
            height: 0.5rem;
            display: block;
        }
    }
}

.remove-all-filters {
    text-decoration: underline;

    &:hover,
    &:focus {
        color: var(--brand-primary);
    }
}

.search-default-view {
    .category-list {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        margin: 0 -1rem;

        &.html-content-category-list {
            margin: 0 -0.86806rem;
        }
        
        & > * {
            flex-shrink: 0;
        }
    }

    h2 {
        margin-bottom: 3rem;
    }

    .category-circle {
        padding: 0 1rem;
        margin-bottom: 2rem;
        text-align: center;
        transition: color 0.3s;
        width: 20%;

        @media (--xl-viewport-down) {
            width: 25%;
        }

        @media (--lg-viewport-down) {
            width: 33.3333%;
        }

        @media (--sm-viewport-down) {
            width: 50%;
        }

        & .category-image {
            border-radius: 50%;
            position: relative;
            padding-top: 100%;
            height: 0;
            overflow: hidden;
            background-color: var(--white);

            & img {
                width: 101%;
                max-width: none;
                height: auto;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                transition: transform 0.75s;

                &.store-image {
                    width: 70%;
                }
            }
        }

        & p {
            margin-top: 0.5rem;
        }
    }

    button.category-circle,
    a.category-circle {
        &:hover,
        &:focus {
            color: var(--brand-primary);

            & .category-image img {
                transform: translate(-50%, -50%) scale(1.1);
            }
        }
    }

    .simon-search-ad-spot-wrapper {
        display: flex;
        padding-top: 1rem;
        @media(--lg-viewport-down) {
            display: block;
        }

        & > .simon-search-ad-spot:first-child {
            margin-right: 0.86806rem;
        }
        & > .simon-search-ad-spot:last-child {
            margin-left: 0.86806rem;
        }

        & .simon-search-ad-spot {
            position: relative;
            width: 50%;
            padding: 0;
            display: block;

            @media(--lg-viewport-down) {
                width: 100%;
                margin-left: 0 !important;
                margin-right: 0 !important;
                margin-bottom: 1rem;
                
            }
        }

        & .simon-search-ad-spot-text {
            position: absolute;
            width: 100%;
            height: auto;
            left: 0;
            top: 50%;
            transform: translateY(-50%);

            & h2 {
                margin-bottom: 0.75rem
            }
        }

        & .simon-search-ad-spot-image {
            position: relative;
            padding-top: 82.07885%;
            height: 0;
            overflow: hidden;

            & img {
                width: 101%;
                height: auto;
                max-width: none;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%) scale(1.001);
                transition: transform 0.5s;
            }
        }

        & .simon-search-ad-spot:hover img, 
        & .simon-search-ad-spot:focus img {
            transform: translate(-50%, -50%) scale(1.05);
        }
    }

    .html-content-category-list {
        
        & .category-circle {
            padding: 0 0.86806rem;

            @media(--md-viewport-down) {
                padding: 0 1rem;
            }
        }
    }
}

.filter-prerender {
    position: relative;
    padding-left: 1.5rem;
    font-size: var(--input-font-size);

    & .checkbox-prerender {
        border: var(--input-btn-border-width) solid var(--brand-gray);
        display: block;
        /*height: var(--checkbox-size);*/
        left: 0;
        position: absolute;
        width: var(--checkbox-size);
        bottom: 0;
        top: 0;
    }

    & .text-prerender {
        display: inline-block;
        width: calc(100% - 1.75rem);
        background-color: var(--gray-light);
        margin-left: 1.5rem;
    }
}

#Color_accordion .filter-prerender {
    display: flex;
    flex-direction: column;
    padding: 0;
    align-items: center;

     & .checkbox-prerender {
        border-radius: 50%;
        width: 2.45rem;
        height: 2.45rem;
        position: relative;
        flex-shrink: 0;
        margin-bottom: 0.25rem;
        background: var(--gray-light);
        outline: 2px solid transparent;
        transition: outline 0.2s;
    }

    & .text-prerender {
        width: 80%;
        margin-left: 0;
    }
}

#Size_accordion .filter-prerender {
    & .checkbox-prerender {
        display: none;
    }
    & .text-prerender {
        padding: 0.5rem;
        border: 2px solid var(--gray-light);
        border-radius: 3px;
        width: 3rem;
        margin-left: 0;
        background: transparent;
    }
    &:nth-child(2n) .text-prerender {
        width: 5rem;
    }
    &:nth-child(n) .text-prerender {
        width: 4rem;
    }
}

.facet-prerender {
    padding: 1rem 2.81rem 1rem 1rem !important;
    position: relative;

    & .text-prerender {
        display: inline-block;
        width: 50%;
        background-color: var(--gray-light);
    }
}

.prerender {
    overflow: hidden;
}

.prerender::after {
    content: '';
    top: 0;
    transform: translateX(100%);
    width: calc(100% + 2px);
    height: calc(100% + 2px);
    position: absolute;
    z-index: 1;
    animation: prerender-shine 2s infinite;

    background: -moz-linear-gradient(left, rgba(255,255,255,0) 0%, rgba(255,255,255,0.8) 50%, rgba(128,186,232,0) 99%, rgba(125,185,232,0) 100%); /* FF3.6+ */
    background: -webkit-gradient(linear, left top, right top, color-stop(0%,rgba(255,255,255,0)), color-stop(50%,rgba(255,255,255,0.8)), color-stop(99%,rgba(128,186,232,0)), color-stop(100%,rgba(125,185,232,0))); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(left, rgba(255,255,255,0) 0%,rgba(255,255,255,0.8) 50%,rgba(128,186,232,0) 99%,rgba(125,185,232,0) 100%); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(left, rgba(255,255,255,0) 0%,rgba(255,255,255,0.8) 50%,rgba(128,186,232,0) 99%,rgba(125,185,232,0) 100%); /* Opera 11.10+ */
    background: -ms-linear-gradient(left, rgba(255,255,255,0) 0%,rgba(255,255,255,0.8) 50%,rgba(128,186,232,0) 99%,rgba(125,185,232,0) 100%); /* IE10+ */
    background: linear-gradient(to right, rgba(255,255,255,0) 0%,rgba(255,255,255,0.8) 50%,rgba(128,186,232,0) 99%,rgba(125,185,232,0) 100%); /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#007db9e8',GradientType=1 ); /* IE6-9 */
}

@keyframes prerender-shine {
    0% {
        transform: translateX(-200%);
    }

    100% {
        transform: translateX(100%);
    }
}

#layers .tooltip {
    background-color: var(--black);
    padding: 0.5rem 0.75rem;
    font-size: 0.8rem;
    color: var(--white);
    z-index: 10000;
    max-width: calc(100vw - 3rem);
}

.back-button-mobile {
    background-color: var(--white);
    border-radius: 50%;
    position: absolute;
    z-index: 2;
    display: block;
    width: 3rem;
    height: 3rem;
    box-shadow: 2px 2px 4px 0px rgba(0,0,0,0.25);
    margin-top: -0.75rem;
    margin-left: -0.25rem;

    @media (--sm-viewport-down) {
        margin-left: 0.5rem;
        margin-top: 0.5rem;
    }

    &:hover,
    &:focus {
        background-color: var(--gray-lighter);

        & svg {
            padding: 0.65rem;
        }
    }

    & svg {
        fill: var(--black);
        width: 3rem;
        height: 3rem;
        padding: 0.75rem;
        transition: padding 0.3s;
    }
}

#suggestedTenants {
    .suggested-tenant-header {
        border-bottom: 1px solid var(--black);
        margin-bottom: 1rem;
    }

    .directory-store .card-secondary {
        @media (--sm-viewport-down) {
            padding: 0.5rem;
            padding-right: 1.5rem;
        }
    }

    .directory-button-container.hidden-sm-up .card-secondary-btn {
        background-color: var(--brand-gray);
        color: var(--white);
        padding: 0.5rem;

        &:hover,
        &:focus {
            background-color: var(--white);
            color: var(--brand-gray);

            & svg {
                fill: var(--brand-gray);
            }
        }

        & svg {
            fill: var(--white);
        }
    }
}

.product-list-error-modal {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(18,50,98,0.95);
    z-index: 20;
    display: flex;
    justify-content: center;
    align-items: flex-start;

    & .error-modal-body {
        max-width: 550px;
        padding-top: 8rem;
        padding-bottom: 8rem;
        position: sticky;
        top: 74px;

        @media (--md-viewport-down) or (max-height: 550px) {
            padding-top: 4rem;
            padding-bottom: 4rem;

            & p {
                font-size: 1rem;
            }
        }
    }

    & p {
        font-size: 1.25rem;

        & svg {
            width: 1.25rem;
            height: 1.25rem;
            margin-right: 0.5rem;
            position: relative;
            top: 0.1rem;
        }
    }

    & svg {
        fill: var(--white);
    }

    .close-wrapper {
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
    }

    & .close {
        position: sticky;
        top: 74px;
        padding: 2rem;
        
        & svg {
            width: 1.25rem;
            height: 1.25rem;
        }

        @media (--md-viewport-down) {
            padding: 0.86806rem;
        }
    }

    & .btn {
        margin-top: 2rem;
    }

}

.product-list-container {
    position: relative;
    min-height: calc(100vh - 74px);
}

.simon-search-app-container {
    min-height: calc(100vh - 74px);
}

.search-filter-info-banner {
    background-color: var(--brand-primary);
    color: var(--white);
    display: flex;
    align-items: center;
    margin-bottom: 1rem;

    & svg {
        width: 1.5rem;
        height: 1.5rem;
        display: block;
        fill: var(--white);
        margin: 0.5rem 1rem 0.5rem 1rem;
        flex-basis: 3.5rem;
    }

    & .search-filter-info-banner-text {
        padding: 0.5rem 1rem;
        padding-left: 0;
        font-size: .76968rem;
    }
}

.product-details-pricing > div {
/*    @media (--lg-viewport-up) {
        display: inline-block;
        margin-left: 0.75rem;
    }*/
    margin-left: 0.75rem;
}

.product-details-pricing {
    display: flex;
    align-items: center;
    justify-content: right;
}

.search-api-error-section > p {
    margin-top: 1rem;
    margin-bottom: 2rem;
}

.product-tag {
    position: absolute;
    bottom: -0.5px;
    right: 0;
    color: var(--brand-primary);
    border: 1px solid var(--brand-primary);
    padding: 0.25rem 0.5rem;
    font-size: 0.75rem;
    background-color: var(--white);
    letter-spacing: -0.03em;
}

.simon-search-toggle {
    display: inline-block;
    overflow-x: hidden;
    position: relative;
    border-radius: 50rem;
    white-space: nowrap;
    
    &:after {
        position: absolute;
        content: '';
        z-index: -4;
        background: var(--white);
        border-radius: 50rem;
        top:0;
        left:0;
        width:100%;
        height:100%;
    }

    & > .directory-button,
    & > .product-button {
        border-radius: 50rem;
        padding: 0.5rem 1rem;
        text-decoration: none;
        display: inline-block;
        transition: background 0.2s ease, color 0.2s ease;
        position: relative;

        &.active {
            color: var(--white) !important;

            &::after {
                left: 0 !important;
            }
        }

        &::after {
            background: var(--brand-primary);
            content: '';
            height: 100%;
            position: absolute;
            transition: left 0.2s cubic-bezier(0.77, 0, 0.175, 1), color 0.2s;
            z-index: -2;
            width: 100%;
            top: 0;
            border-radius: 50rem;
        }

        &.toggle-button-right::after {
            left: -100%;
        }
        &.toggle-button-left::after {
            left: 100%;
        }
    }

    & .toggle-focus-indicator {
        opacity: 0;
        transition: opacity 0.3s, border-width 0.15s;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        pointer-events: none;
        border-color: var(--brand-gray);
        border-width: 0px;
        border-style: solid;
        border-radius: 50rem;
        z-index: -3;
    }

    &:focus > .toggle-focus-indicator {
        opacity: 1;
        border-width: 1px;
    }
}

.search-sidebar-column {
    @media (min-width: 1600px) {
        width: 20%;
    }
}

.search-results-column {
    @media (min-width: 1600px) {
        width: 80%;
    }
}
.simon-search-list-view-header {
    @media (min-width: 1600px) {
        & .search-header {
            width: 20%;
        }
        & .search-results-count {
            width: 55%;
        }
    }
    @media (--md-viewport-down) {
        text-align: center;

        & > .text-right {
            text-align: center;
            margin-bottom: 1rem;
        }
    }
}

.directory-other-results {
    & h4 {
        margin: 3.5rem 0;
    }
    & .row {
        display: flex;
        flex-wrap: wrap;
    }
    & .row > div {
        flex-shrink: 0;
        margin-bottom: 1rem;
    }
}

.simon-search-app-container .directory-store-tag {
    position: absolute;
    top: 0;
    right: 0.8680555rem;
    z-index: 2;
}