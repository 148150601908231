/* Homepage */

.vip-hero-lg {
    height: auto;
    text-align: left;
    background-position: center top;
    padding: 0;
    background-color: #f1f1f1;

    @media (--sm-viewport-down) {
        height: auto;
        background-color: transparent;
    }

    & > .container {
    width: 100%;

    @media (--lg-viewport-up) {
      align-items: flex-start;
    }
  }

  & > .container > .row {
    width: 100%;
  }

  & .vip-img-logo-mobile {
      @media (--sm-viewport-down) {
          position: relative;

          & img {
              display: block;
          }

          & .vip-login-header > .vip-header-logo-img {
              position: absolute;
              bottom: 0;
              left: 5%;
              right: 5%;
              width: 90%;
          } 
      }
  }
}

.vip-header-logo-img {
    width: 16rem;
}

.vip-header {
    @media(--md-viewport-down) {
        font-size: var(--font-size-h2);
    }
}

.vip-hero-lg,
.vip-login-bg {
    background-image: url("https://assets.simon.com/content/vip/VIP-Banner-1439x785.jpg");
    background-repeat: no-repeat;
    background-size: auto 100%;
    background-position: center;
    &.has-error, &.expanded {
    /*background-size: auto 785px;*/
  }
  
  @media (--xl-viewport-down) {
    background-image: url("https://assets.simon.com/content/vip/VIP-Banner-1439x785.jpg");
  }

  @media (--lg-viewport-down) {
    background-image: url("https://assets.simon.com/content/vip/VIP-Banner-976x703.jpg");
    /*background-size: auto auto;*/
  }

  @media (--md-viewport-down) {
    background-image: none;
  }
}

.vip-login-bg {
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  z-index: 99;
  /*filter: blur(5px); 
  filter: url("#blur");
  filter:progid:DXImageTransform.Microsoft.Blur(PixelRadius='5');*/ 
  background-position: 0;
  
  @media (--xl-viewport-down) {
    background-position: 6px -94px;
  }

  @media (--lg-viewport-down) {
    background-position: -79px -60px;
  }
  
  &.has-error,
  &.expanded {
    background-position: -156px -58px;

    @media (--xl-viewport-down) {
      background-position: -117px -57px;
    }

    @media (--lg-viewport-down) {
      background-position: -177px -62px;
    }
  }
}

.vip-login-form {
  background-color: #faa892;
  z-index: 100;
  padding: 1.9rem;
  position: relative;

  @media (--sm-viewport-down) {
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 15px;
    background-color: transparent;

    & .btn {
        background-color: var(--black);
        color: var(--white);
    }
  }

  .list-inline {
    @media (--sm-viewport-down) {
      font-size: 13px;
    }
  }

  /*input:-webkit-autofill,
  input:-webkit-autofill:hover, 
  input:-webkit-autofill:focus
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    border: none;
    -webkit-text-fill-color: white;
    -webkit-box-shadow: 0 0 0px 1000px #A6A4A7 inset !important;
    transition: background-color 5000s ease-in-out 0s;
  }*/
}

.vip-login {
  margin-top: 57px;
  margin-bottom: 57px;  
  position: relative;

  @media (--sm-viewport-down) {
    margin-top: 0;
    margin-bottom: 0;
  }


  &-header {
    margin-bottom: 2.29824rem;

    @media (--lg-viewport-down) {
      font-size: var(--font-size-h2);
    }

    @media (--md-viewport-down) {
      font-size: var(--font-size-h1);
    }
  }
}

.vip-login-form .form-control,
.vip-login-form label.floating.floating-accessibly + .form-control,
.vip-login-form label.floating.floating-accessibly+div>.form-control {
  color: var(--black);
  border: 1px solid transparent;
  box-shadow: none;
  background-color: rgba(255, 255, 255, 1);

  &::placeholder {
    color: var(--gray-darker);
  }

  &:focus::placeholder,
  &:active::placeholder {
    color: var(--gray);
  }

  &:focus {
    border-color: var(--black);

    &.show-password-field {
      border-right:none;
    }

    & ~ .show-password-icon,
    & ~ .hide-password-icon {
      border-color: var(--black);
      border-left: none;
    }
  }

  @media(--sm-viewport-down) {
    background-color: var(--gray-lighter);

    & ~ .show-password-icon,
    & ~ .hide-password-icon {
      background-color: var(--gray-lighter);
    }
  }
}

.vip-login-form .form-control ~ .show-password-icon,
.vip-login-form .form-control ~ .hide-password-icon {
  border: 1px solid transparent;
}

.vip-login-form .checkbox .c-indicator {
  background-color: transparent;
  border-color: var(--white);
}

.vip-login-form .checkbox input[type=checkbox]:checked~.c-indicator {
  border-color: var(--white);
  background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDIwLjEuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxMC43IDgiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDEwLjcgODsiIHhtbDpzcGFjZT0icHJlc2VydmUiPgo8c3R5bGUgdHlwZT0idGV4dC9jc3MiPgoJLnN0MHtmaWxsOiNmZmY7fQo8L3N0eWxlPgo8cGF0aCBjbGFzcz0ic3QwIiBkPSJNNCw4QzQsOCw0LDgsNCw4QzMuNyw4LDMuNCw3LjgsMy4xLDcuNUwwLDQuM2wxLjgtMS44TDQsNC44TDguOSwwbDEuOCwxLjhMNC45LDcuNkM0LjcsNy44LDQuNCw4LDQsOHoiLz4KPC9zdmc+Cg==");
}

.vip-login-form .form-group {
  margin-bottom: 1.5rem;
  margin-top: 0;
}

.vip-login-form .form-group:not(.has-error):not(.js-captcha) label,
.vip-login-form .nav-link {
  color: var(--gray-darker);
}

.vip-login-form .btn-secondary:hover {
  background-color: var(--black);
  color: var(--white);
}

.vip-login-form .btn-secondary:focus {
  background-color: var(--black);
  color: var(--white);
}

.vip-login-form .nav-link:hover {
  color: var(--black); 
} 

.vip-login-form .has-error, 
.vip-login-form .has-error label,
.vip-login-form .has-error.form-control,
.vip-login-form .has-error .form-control {
  color: #c43679;
  border-color: #c43679;
}

.vip-login-form .has-error .form-control:focus {
  box-shadow: inset 0 0 0 1px #c43679;
}

.btn-register {
  margin-top: 0.76968rem;
}

.img-phone {
  margin-top: 0;

  @media (--lg-viewport-up) {
    margin-top: -128px;
  }
}

.vip-info {
  padding-bottom: 0;
  
  & > .container > .row {
    @media (--sm-viewport-down) {
      display: flex;
      flex-direction: column;
    }

    & > *:not(.top-on-mobile) {
      order: 2;
    }
  }
  
  &-header {
    margin-bottom: 1.5rem;

    @media (--sm-viewport-down) {
      font-size: var(--font-size-h4);
      text-align: center;
    }
  }

  &-desc {
    margin-bottom: 0;

    @media (--lg-viewport-down) {
      margin-bottom: 3rem;
    }

    @media (--sm-viewport-down) {
      margin-bottom: 0;
      padding-left: 15px;
      padding-right: 15px;
    }
  }

  &-lists {
    margin-top: 2.9rem;
    margin-bottom: 3rem;

    @media (--lg-viewport-down) {
      margin-top: 0;
    }

    @media (--md-viewport-down) {
      padding-bottom: 1.6rem;
      margin-bottom: 0;
    }

    & .col-lg-6:last-of-type > .vip-info-lists-header {
      @media (--lg-viewport-down) {
        margin-top: .9rem;
      }

      @media (--sm-viewport-down) {
        margin-top: 1.5rem;
      }
    }

    ul {
      padding-left: var(--spacer);
    }    

    &-header {
      margin-bottom: .9rem;

      @media (--sm-viewport-down) {
        font-size: var(--font-size-h6);
      }
    }
  }

  &-btn {
    padding-top: 0;
    padding-bottom: 1.9152rem;
  }

  &-btn > * {
    @media (--sm-viewport-down) {
      margin-left: 15px;
    }
  }
}

.validation-summary-errors {
  ul {
    @mixin list-unstyled;

    margin: 0;
    padding: 0;
  }

  li {
    color: #c43679;
    margin-bottom: 1.9rem;
    margin-top: 0;
    font-family: "SimonCircularPro-Bold", sans-serif;
  }
}

.vip-info-box {
  background-color: #f1f1f1;
  height: 280px;
  display: flex;
  align-items: center;
  margin-top: 30px;
  padding: 0 30px;

  @media(--xl-viewport-down){
    padding: 0 10px;
  }
  @media(--sm-viewport-down) {
    height: auto;
  }

  & > .vip-info-box-text {
    flex: 1;
    padding-left: 30px;

    @media(--xl-viewport-down) {
      padding-left: 10px;
    }
    
    @media(--lg-viewport-down) {
      padding-left: 30px;
    }
    
    @media(--sm-viewport-down) {
      flex: 1;
      padding: 10px;
    }
  }

  & > .vip-info-box-text > * {
    @media(--sm-viewport-down) {
      font-size: 1rem;
    }
  }

  & > .vip-info-box-img {
    align-content: flex-end;
    display: flex;
    width: auto;

    @media(--sm-viewport-down) {
      text-align: center;
      display: block;
      height: auto;
      flex: 1;
    }
  }
}

.text-vip-pink {
    color: #ed4380;
}

@media(--lg-viewport-down) {
    .jumbotron-title {
        font-size: 2.29824rem;
    }
    .jumbotron-banner-no-stack .jumbotron-desc {
        font-size: 1.2rem;
    }
}