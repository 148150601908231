.vip-register .simon-section-aside,
.vip-profile .simon-section-aside {
  margin-bottom: 2.29824rem;
}

.favorite-brands {
  margin-bottom: 1.9152rem;

  .sortable-active:after {
    content: 'CLICK TO SWAP FAVORITE BRAND';
  }
}

.favorite-brands .sortable:not(.sortable-by-primary) {
  cursor: auto;

  &:first-child {
      margin-top: 0;
  }
}

.form-security {
  margin: 10px 0;

  @media (--lg-viewport-down) {
    margin: 22px 0;
  }
}

.favorite-centers {
  .sortable-active:after {
    content: 'CLICK TO SWAP PRIMARY CENTER';
  }
}

fieldset.selected-favorite {
  top: -14px;
  legend {
    font-size: .6414rem;
    opacity: 0;
    transition: all ease-in 0.3s
  }
}

label a.text-brand-primary:focus {
  color: var(--brand-gray);
}