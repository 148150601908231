/* New VIP Offers page styles */

.simon-section-plum,
.offers-print {
  background-color: var(--brand-primary);
}

.vip-offers-header {
  @media (--lg-viewport-down) {
    text-align: center;
  }
}

.change-offers p {
  margin-bottom: 1.9152rem;
}

.offers-nav {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;

  @media (--lg-viewport-down) {
    .nav-pills {
      display: flex;
      width: 100%;
    }

    .nav-pills .nav-item {
      flex: 1;
      text-align: center;
    }
  }
}

.offers-filters {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 1.10rem;
  border-bottom: 1px solid var(--brand-gray);
  padding: 9px 0;

  & .active .nav-link {
    color: var(--brand-primary);

    svg {
      fill: var(--gray);
    }
  }

  & .nav-link.no-hover:hover,
  & .nav-link.no-hover:focus {
    color: var(--gray);
  } 
  

  svg {
    fill: var(--gray);
    position: relative;
    top: 2px;
    margin-right: 2px;
  }

  .nav.nav-inline {
    font-size: 1.045rem;
  }

  @media (--sm-viewport-down) {
    margin-bottom: 0;
    flex-direction: column;
    padding: 0;
    background-color: var(--brand-gray);
    .offers-filters-toggle {
        padding: 5px 15px;
        text-align: right;
        svg {
        margin-right: 0;
        margin-left: 5px;
        top: 0;
      }
    }

    svg {
      fill: var(--white);
    }
    .offer-sort-icon {
      float: right;
      right: -5px;
      fill: var(--gray);
    }

    .nav.nav-inline {
      background-color: var(--gray-lighter);
      padding-left: 15px;
      padding-right: 15px;
      padding-bottom: 30px;
    }

    .nav.nav-inline > .nav-item {
      display: block;
      margin-left: 0;

      & + .nav-item {
        margin-top: 15px;
      }
    }
    
    .nav.nav-inline > .nav-item > .nav-link,
    .nav.nav-inline > .nav-item > select {
      appearance: none;
      backface-visibility: hidden;
      background-color: transparent; /* var(--input-bg) */
      background-image: none;
      border: var(--input-btn-border-width) solid var(--input-border-color);
      border-radius: 0;
      color: var(--input-color-placeholder);
      display: block;
      font-size: var(--input-font-size);
      font-weight: normal;
      letter-spacing: -0.01em;
      line-height: var(--body-line-height);
      padding: 0 var(--input-padding-x);
      width: 100%;
      text-transform: uppercase;

      @mixin transition box-shadow 0.3s, ease-in-out;
      
      &:not(select) {
        padding: var(--input-padding-y) var(--input-padding-x);
      } 
    }

    .nav.nav-inline > .nav-item.active > .nav-link {
      background-color: var(--gray);
      color: var(--white);

      .offer-sort-icon {
        fill: var(--white);
      }
    }

    .nav.nav-inline > .nav-item > select {
      appearance: none;
      background-image: var(--icon-down-arrow);
      background-repeat: no-repeat;
      background-color: transparent;
      background-position: right 15px top 50%;
      background-size: 20px 20px;
      padding-right: 2.75rem;
      cursor: pointer;
      text-indent: 0.01px;
      text-overflow: "";

      &:not([size]):not([multiple]) {
        height: 53px; /* calc(3.06rem + 2px) */
      }
    }
  }
}

.vip-offer-dropdown {
  background-image: var(--icon-down-arrow);
  background-repeat: no-repeat;
  background-color: transparent;
  background-position: right 0 top 50%;
  background-size: 13px 13px;
  color: var(--gray);
  border: none;
  appearance: none;
  cursor: pointer;
  text-indent: 0.01px;
  text-overflow: "";

  @media (--sm-viewport-down) {
    width: 100% !important;
  }
  
  &.categories {
    width: 85px;
  }
  
  &.stores {
    width: 56px;
  }

  option {
    color: black;
  }

  & + select {
    display: none;
  }
}

.offers-print {
  .nav-link {
    color: var(--white);
  }

  .nav-link.disabled {
    cursor: not-allowed;
    opacity: .65;

    &:hover + .vip-print-tooltip {
      opacity: 1;
    }
  }

  &.nav-pills .nav-item:first-child {
    border-left-color: var(--brand-primary);
    border-top-color: var(--brand-primary);
    border-bottom-color: var(--brand-primary);
  }

  &.nav-pills .nav-item:not(:first-child):not(.no-border):before {
    background: var(--white);
  }

  &.nav-pills .nav-item:last-child {
    border-right-color: var(--brand-primary);
    border-top-color: var(--brand-primary);
    border-bottom-color: var(--brand-primary);
  }

  &.nav-pills .nav-item:last-child .nav-link {
    width: 53px;
    padding-left: 0;
    padding-right: 0;
    text-align: center;
  }


  .vip-print-tooltip {
    position: absolute;
    top: -1rem;
    left: 50%;
    z-index: 2;
    transform: translate(-50%, -100%);
    width: 100%;
    padding: 1rem;
    background-color: var(--white);
    border: 1px solid var(--brand-primary);
    opacity: 0;
    transition: opacity 0.3s;

    &::before {
      content: " ";
      position: absolute;
      top: calc(100% - 2px);
      left: 50%;
      margin-left: -1rem;
      border-width: 1rem;
      border-style: solid;
      z-index: 2;
      border-color: var(--white) transparent transparent transparent;
    }

    &::after {
      content: " ";
      position: absolute;
      top: 100%;
      left: 50%;
      margin-left: -1rem;
      border-width: 1rem;
      border-style: solid;
      border-color: var(--brand-primary) transparent transparent transparent;
    }
  }
}

.vip-offers {
  padding-top: 2.5rem;
  @media (--sm-viewport-down) {
    padding-bottom: 0;
  }
}

.vip-offer {
  display: flex;
  background-color: var(--white);
  cursor: pointer;
  position: relative;

  & + .vip-offer {
    margin-top: 1.10rem;

    @media (--sm-viewport-down) {
      margin-top: 0;
      border-top: 1px solid var(--gray);
    }
  }

  &:last-of-type {
    @media (--sm-viewport-down) {
      border-bottom: 1px solid var(--gray);
    }
  }

  &-count {
    margin-top: 30px;
    margin-bottom: 0;
  }

  &.selected {
    .vip-offer-check {
      background-color: var(--brand-primary);
    }
  }

  &.open {
    .vip-offer-details {
      white-space: normal;
      overflow: initial;
      text-overflow: initial;
    }

    .vip-offer-details .vip-offer-text {
      margin-bottom: 1.3rem;
    }

    .legal-text,
    .vip-full-offer {
      display: block;
    }

    .vip-full-offer {
      margin-top: 1.04rem;
      margin-bottom: 0;
    }
  }

  @media(screen) {
    &:not(.open) .vip-offer-desc {
      max-height: 1.5rem;
    }
  }

  @media (--lg-viewport-down) {
    &:not(.open) .vip-offer-desc,
    &:not(.open) .vip-offer-title {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  @media print and (--lg-viewport-down) {
    &:not(.open) .vip-offer-desc,
    &:not(.open) .vip-offer-title {
      white-space: normal;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  @media (--sm-viewport-down) {
    &:not(.open) .vip-offer-desc,
    &:not(.open) .vip-offer-intDesc {
      display: none;
    }
  }
}

.vip-offer-img {
  padding: 1.45rem;
  height: 170px;
  width: 170px;
}

.vip-offer.open .vip-offer-img-mobile {
  display: none;
  
  @media (--sm-viewport-down) {
    display: block;
    max-width: 75px;
  }
}

.vip-offer:not(.open) .vip-offer-img-mobile {
  display: none;
}


.vip-offer .legal-text,
.vip-full-offer {
  display: none;
}

.vip-offer-details {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1.45rem 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media (--lg-viewport-down) {
    padding-right: 15px;
    white-space: normal;
  }

  @media (--sm-viewport-down) {
    white-space: normal;
    padding-left: 15px;
    padding-top: 1.21rem;
    padding-bottom: 1.21rem;
  }
}

.vip-offer-text {
  flex: 1 0 auto;
}

.vip-offer-store {
  margin-bottom: 7px;

  @media (--sm-viewport-down) {
    margin-bottom: 8px;
  }
}

.fonts-loaded .vip-offer-store {
  font-family: 'SimonCircularPro-Book';
}

.vip-offer-title {
  margin-bottom: 11px;
  line-height: 1.05;
  max-width: calc(100% - 50px);

  @media (--md-viewport-down) {
    max-width: calc(100% - 30px);
  }

  @media (--sm-viewport-down) {
    font-size: var(--base-font-size);
    margin-bottom: 13px;
  }
}

.vip-offer-desc,
.vip-offer-title {
  text-overflow: ellipsis;
  overflow: hidden;
  margin-bottom: 1.04rem;
}

.vip-offer-footer {
  display: flex;
  justify-content: space-between;
}

.vip-offer-date,
.vip-offer-more {
  color: var(--card-footer-color);
  letter-spacing: 0.05em;
  font-size: var(--card-footer-font-size);
  padding: 0;
}

.vip-offer-date-icon,
.vip-offer-more-icon {
  width: var(--card-icon-size);
  height: var(--card-icon-size);
  fill: var(--card-footer-color);
  display: inline-block;
}

.vip-offer-date-icon {
  margin-right: 7px;
}

.vip-offer-more-icon {
  margin-left: 2px;
}

.vip-offer-more-mobile {
  display: flex;
  margin-left: 0;
  justify-content: center;
  align-items: center;

  svg {
    fill: var(--card-footer-color);
  }
}

.vip-offer-favorite {
  position: absolute;
  top: 1.45rem;
  right: 53px;

  @media (--lg-viewport-down) {
    top: 1rem;
    right: 0.75rem;
  }

  @media (--md-viewport-down) {
    & svg {
      width: 30px;
      height: 30px;
    }
  }
}

.vip-offer-favorite .vip-offer-favorite-heart-filled {
  display: none;
}

.vip-offer.favorite .vip-offer-favorite .vip-offer-favorite-heart-filled {
  display: block;
}
.vip-offer.favorite .vip-offer-favorite .vip-offer-favorite-heart-outline {
  display: none;
}

.vip-favorites-update-popup {
  position: absolute;
  left: -0.5rem;
  top: 50%;
  transform: translate(-100%, -50%);
  padding: 0.75rem;
  width: auto;
  background-color: var(--white);
  color: var(--brand-primary);
  border: 1px solid var(--brand-primary);
  white-space: nowrap;
  display: none;
  @media(--md-viewport-down) {
    font-size: 0.8rem;
    padding: 0.5rem;
  }
}


.vip-offer-check {
  display: flex;
  width: 33px;
  background-color: var(--brand-gray);
  margin-left: 20px;
  justify-content: center;
  align-items: center;

  @mixin transition background-color, 0.3s, ease;

  @media (--lg-viewport-down) {
    display: none;
  }

  .checkbox {
    margin: 1px 0 0 -1px;
    width: 15px;
    height: 15px;
  }

  .checkbox .c-indicator {
    background-color: transparent;
    border-color: var(--white);
  }

  .checkbox input[type="checkbox"] {
    &:checked ~ .c-indicator {
      background-image: var(--icon-checkbox-white);
      border-color: var(--white);
    }
  }

  label {
    display: block;
  }
}

.vip-offer-loading {
  @media (--sm-viewport-down) {
    height: 105px;

    & * {
      visibility: hidden;
    }
  }

  img {
    border: 1px solid #f1f1f1;
  }

  .vip-offer-title,
  .vip-offer-store,
  .vip-offer-date,
  .vip-offer-more {
    &,
    & * {
      background-color: #f1f1f1;
      color: #f1f1f1;
    }

    & svg {
      fill: #f1f1f1;
    }
  }
}

.modal-dialog .modal-title {
  font-size: 2.29824rem;
}

@media (--lg-viewport-down) {
  svg.fill-white-lg-down {
    fill: var(--white);
  }
}

@media (--md-viewport-down) {
  svg.fill-white-md-down {
    fill: var(--white);
  }
}

@media (--sm-viewport-down) {
  svg.fill-white-sm-down {
    fill: var(--white);
  }
}

.modal.cardless-modal {
    @media (--sm-viewport-down) {
        width: 90%;
    }
}

.cardless-header {
    font-size: 2rem;
    line-height: 0.9;
    margin-bottom: 1rem;
    margin-top: 3rem;

    @media(--md-viewport-down) {
        font-size: 1.75rem;
    }
    @media(--sm-viewport-down) {
        font-size: 1.25rem;
    }
}

.cardless-modal .header-md {
    @media(--sm-viewport-down) {
        font-size: 1.25rem;
    }
}

.cardless-modal .modal-details {
    position: static;
    width: 100%;
    padding: 0;
}

.cardless-header ~ .cardless-header {
    margin: 0;
}

.cardless-modal .modal-content {
    /*background-image: url("https://assets.simon.com/content/cardless/Simon_Credit_Card_Background.svg");*/
    background-size: cover;
    background-position: center;
    overflow-y: hidden;

    @media (--md-viewport-down) {
        /*background-image: url("https://assets.simon.com/content/cardless/468x712_background.svg");*/
    }
}

.cardless-modal .btn {
    margin-top: 2rem;
    margin-bottom: 3rem;

    @media(--md-viewport-down) {
        margin-top: 1rem;
        margin-bottom: 1rem;
    }
}

.cardless-modal .legal-text {
    font-size: 0.65rem;
}

.cardless-modal .row {
    @media(--md-viewport-up) {
        display: flex;
        align-items: center;
    }
}

.cardless-mobile-img {
    max-width: 300px;

    @media(--md-viewport-down) {
        display:block;
        margin: 0 auto;
    }

    @media(--sm-viewport-down) {
        max-width: 90%;
        display: block;
        margin: 0 auto;
    }
}