/* Project Specific Print Blacklist */

@media print {
  html {
    font-size: 90%;
  }

  svg {
    fill: var(--black) !important;
  }

  .container {
    max-width: 100% !important;
  }

  .dont-print,
  header,
  footer,
  picture,
  .jumbotron-bg,
  img,
  .all-offers .vip-offer {
    display: none !important;
  }

  .simon-section {
    padding: 0;
  }

  main {
    margin-top: 0;
  }

  .print-me,
  .print-me.hidden-xs-up {
    display: block !important;
  }

  .vip-offer {
    margin-bottom: 30px;
    margin-top: 0;
    -webkit-column-break-inside: avoid; /* Chrome, Safari */
    page-break-inside: avoid;           /* Theoretically FF 20+ */
    break-inside: avoid-column;         /* IE 11 */
    display:table;                      /* Actually FF 20+ */
    word-wrap: break-word; 
  }

  .vip-offer a[href]::after {
    content: "";
  }

  .vip-offer + .vip-offer {
      margin-top: 0;
  }

  .vip-offer-details,
  .vip-offer-desc {
    white-space: normal !important;
    overflow: auto;
    text-overflow: initial;
    word-wrap: break-word;
    display: block;
  }

  .vip-offer-title {
    font-size: var(--base-font-size);
    word-wrap: break-word;
  }

  .vip-offer-text .legal-text {
    margin-bottom: 15px !important;
    word-wrap: break-word;
  }

  .offers-print-parent {
    column-count: 2;
    padding-left: 0;
    padding-right: 0;
    word-wrap: break-word;
  }

  .offers-print-header {
    border-bottom: 2px solid var(--black);
    margin-bottom: 10px;
    padding-bottom: 25px;
    padding-top: 25px;
    margin-left: 3px;
    padding-right: 15px;
  }

  .offers-print-header p {
    margin-top: 15px;
    margin-bottom: 0;
  }

  .vip-voucher {
    margin-top: 15px;
  }

  .offers-print-header .col-xs-6,
  .offers-print-header .col-xs-12,
  .vip-voucher .col-xs-12 {
    padding-left: 0;
    padding-right: 0;
  }
}
#storeFilterSizer, #categoryFilterSizer {
    -webkit-appearance: menulist-button;
}